import { Col, Container, Row } from "react-bootstrap";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import './styles.css'

export default function Final() {


    return (
        <Container className="container">
            
            <div class="d-flex justify-content-center">

                    <Player
                        autoplay
                        loop
                        src="https://lottie.host/87bc1ec7-6e59-4e57-8b07-b182421e3503/khSOum183h.json"
                        style={{ height: '300px', width: '300px' }}
                        >
                        <Controls visible={false} />
                    </Player>
                        </div>
            <div class="d-flex justify-content-center" className="text">Assinatura Salva com Sucesso!</div>
        </Container>
    )
}