/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import SignatureCanvas from 'react-signature-canvas'
import './styles.css'
import { Buffer } from "buffer";
import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import Image from 'react-bootstrap/Image'
import { API } from "../../Api.ts";

import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";

function useScreenWidth() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return screenWidth;
}

function useScreenHeight() {
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    useEffect(() => {
        function handleResize() {
            setScreenHeight(window.innerHeight);
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return screenHeight;
}



export default function Assinatura() {

    const [loading, setLoading] = useState(false)
    const [msgErro, setMsgErro] = useState()
    const navigate = useNavigate()

    const screenWidth = useScreenWidth();
    const screenHeight = useScreenHeight();

    let sigPad = {}

    const clear = () => {
        sigPad.clear()
    }

    const trim = async () => {
        if (loading)
            return
       
        if(sigPad.isEmpty()){
            setMsgErro('Realize a assinatura')
            setTimeout(() => {
                setMsgErro('')
            }, [1000])
            return
        }

        const base64 = sigPad.getTrimmedCanvas().toDataURL('base64')

        try {
            setLoading(true)
            const fileName = uuidv4().toUpperCase() + '.png';

            const urlParams = new URLSearchParams(window.location.search)
            const queryParams = urlParams.toString() + '&arquivo=' + fileName;

            const response = await fetch(`${API}/link/?${queryParams}`)

            const urlAWS = await response.text()

            console.log('URL AWS', urlAWS)
            const buffer = Buffer.from(
                base64.replace(/^data:image\/\w+;base64,/, ''),
                'base64',
            )

            const uploadResponse = await fetch(urlAWS, {
                method: 'PUT',
                body: buffer,
                headers: {
                    EncodingType: 'base64',
                    ContentType: 'image/png'
                }
            })

            if (uploadResponse.status !== 200) {
                console.log('Erro', uploadResponse.status)
                return setMsgErro(`Ocorreu um erro ao tentar salvar a assinatura`)
            }

            const uploadAssinatura = await fetch(`${API}/salvaassinatura/?${queryParams}`, {
                method: 'PUT',
                headers: {
                    data: new Date()
                }
            })

            if (uploadAssinatura.status !== 200) {
                console.log('Erro', uploadAssinatura.status)
                return setMsgErro(`Ocorreu um erro ao enviar a assinatura`)
            }

            return navigate(`/finalizado`, { replace: true })

        } catch (error) {
            console.log(`error: `,error.message)
            setMsgErro('Ocorreu um erro ao tentar salvar a assinatura')
        } finally {
            setLoading(false)
        }
    }

    if (screenHeight > screenWidth) {
        return (
            <Container className="p-3">
                Gire seu aparelho para realizar a assinatura
            </Container>
        )
    }

    return (
        <Container fluid>
            <div className="containerSig">
                <Row className='containerAssine'>

                    <Col >
                        <Image src="https://appmotorista.sigest.dev.br:8202/v1/image/logo_grande.png" rounded
                            height={35} width={35} className='pr-10' margin={20} />
                    </Col>
                    <Col>
                        Assine no Campo abaixo
                    </Col>
                    <Col>
                        <Stack direction="horizontal" gap={5}>
                            {loading
                                ?
                                <Button variant="warning" size="lg" onClick={clear}>
                                    <div className="spinner-border text-light" role="status" />
                                </Button>
                                :
                                <Button variant="warning" size="lg" onClick={clear}>
                                    Limpar
                                </Button>
                            }
                            {loading
                                ?
                                <Button variant="success" size="lg">
                                    <div className="spinner-border text-light" role="status" />
                                </Button>
                                :
                                <Button variant="success" size="lg" onClick={trim}>
                                    Enviar
                                </Button>
                            }
                        </Stack>
                    </Col>
                </Row>
            </div>

            {msgErro ?
                <div className="alert alert-danger" role="alert">
                    {msgErro}
                </div> : null
            }

            <div className="containerSig">
                <SignatureCanvas penColor='blue'
                    canvasProps={{
                        width: screenWidth * 0.9,
                        height: screenWidth * 0.9 / 1.6 > screenHeight * 0.7 ? screenHeight * 0.7 : screenWidth * 0.9 / 1.6,
                        className: 'sigCanvas'
                    }}
                    ref={(ref) => { sigPad = ref }}
                />
            </div>
        </Container>
    )
}