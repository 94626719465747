import {Routes, Route} from 'react-router-dom'
import Home from './screens/home'
import Assinatura from './screens/assinatura'
import Final from './screens/Final'

export function Router(){
    return(
        <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/assinatura' element={<Assinatura />}/>
            <Route path='/finalizado' element={<Final />}/>
        </Routes>
    )
}