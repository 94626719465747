import { useEffect, useState } from 'react';
import { Button, Col, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import { useNavigate } from 'react-router-dom';
import './styles.css'
import { API, api } from '../../Api.ts';

export default function Home() {
    const [cpf, setCPF] = useState('')
    const [erroCPF, setErroCPF] = useState(false)
    const [url, setURL] = useState('')
    const navigate = useNavigate()
    const [msgErro, setMsgErro] = useState()


    // function GetURL(){
    //     const urlParams = new URLSearchParams(window.location.search)

    //     const treinamento = urlParams.get('treinamento')
    //     if (treinamento && treinamento.toLocaleLowerCase() === 's') {
    //         const instrutor = urlParams.get('instrutor')
    //         if (instrutor) {
    //             const id = urlParams.get('id')
    //             if (id) {                    
    //                 return `/assinatura/?treinamento=s&instrutor=s&id=${id}`
    //             }
    //         }


    //         const listapresenca = urlParams.get('listapresenca')
    //         if (listapresenca && listapresenca.toLocaleLowerCase() === 's') {
    //             const id = urlParams.get('id')
    //             if (id) {
    //                 return `/assinatura/?treinamento=s&listapresenca=s&id=${id}`
    //             }
    //         }

    //         const certificado = urlParams.get('certificado')
    //         if (certificado && certificado.toLocaleLowerCase() === 's') {
    //             const id = urlParams.get('idusuario')
    //             if (id) {
    //                 return `/assinatura/?treinamento=s&certificado=s&id=${id}`
    //             }
    //         }
    //     }

    //     const ata = urlParams.get('ata')
    //     if (ata) {
    //         const id = urlParams.get('assinatura')
    //         if (id) {
    //             return `/assinatura/?ata=${ata}&assinatura=${id}`
    //         }
    //     }
    // }

    useEffect(() => {

        const urlParams = new URLSearchParams(window.location.search)
        setURL(urlParams.toString())
        // termo
        // checklist
        // checklistetapa
    }, [])

    async function Navegar(e) {

        if (cpf.length !== 11) {
            return setErroCPF(true)
        }

        // aqui consulta a api para validar os dados
        try {
            console.log('API', `${API}/?${url}&cpf=${cpf}`)
            const response = await fetch(`${API}/?${url}&cpf=${cpf}`, {
                method: 'GET'
            });

            const data = await response.json()

            if (data.error) {
                setMsgErro(data.message)
            } else {
                return navigate(`/assinatura/?${url}&cpf=${cpf}`, { replace: true })
            }
        } catch (error) {
            setMsgErro(error.message)
        }
    }

    return (
        <Container>
            <Form>
                <Form.Group as={Row} className="containerLogo">
                    <Col className='logo'>
                        <Image src="https://appmotorista.sigest.dev.br:8202/v1/image/logo_grande.png" rounded
                            height={35} width={35} />
                    </Col>
                    <Col className='titulo'>
                        <Form.Control plaintext readOnly defaultValue="Sigest - Assinaturas" />
                    </Col>
                </Form.Group>

                <Form.Group className='mb-3' controlId='cpf'>
                    <Form.Label>Para começar digite seu CPF</Form.Label>
                    <Form.Control type='text' placeholder='Digite aqui...' maxLength={11}
                        value={cpf}
                        onChange={(e) => setCPF(e.target.value.replace(/\D/, ''))}
                    />
                    {erroCPF ?
                        <Form.Label className='labelErro'>Digite corretamente seu CPF</Form.Label>
                        : null}
                </Form.Group>

                <Form.Group className='mb-3' controlId='cpf'>
                    <Button onClick={Navegar}>Avançar</Button>
                </Form.Group>
                {msgErro ?
                    <div className="alert alert-danger" role="alert">
                        {msgErro}
                    </div> : null
                }
            </Form>
        </Container>
    )
}